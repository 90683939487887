const ADS_URL =
    'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

export const checkAdBlockers = (callback) => {
    const xhr = new XMLHttpRequest();

    xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            callback(xhr.status === 0 || xhr.responseURL !== ADS_URL);
        }
    };

    xhr.open('HEAD', ADS_URL, true);
    xhr.send(null);
};
