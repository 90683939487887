export const isIOS = () => {
    // For browsers supporting navigator.userAgentData
    if (navigator.userAgentData) {
        if (
            navigator.userAgentData.platform &&
            navigator.userAgentData.brands
        ) {
            return navigator.userAgentData.platform === 'iOS';
        }
    }

    // Fallback for older browsers
    const userAgent = window.navigator.userAgent || navigator.userAgent;

    return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
};

export const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check for Android devices
    if (/android/i.test(userAgent)) return true;
    // Check for iOS devices (iPhone/iPod/iPad)
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return true;
    // Check for "mobile" in the user agent
    if (/mobile/i.test(userAgent)) return true;
    // Check for small screen size (common for mobile devices)
    return window.innerWidth <= 800 && window.innerHeight <= 600;
};

export const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/mobile/i.test(ua)) {
        return 'mobile';
    } else if (
        /tablet/i.test(ua) ||
        (navigator.maxTouchPoints && navigator.maxTouchPoints > 1)
    ) {
        return 'tablet';
    } else {
        return 'desktop';
    }
};

export const isWebView = () => {
    if (typeof window === undefined) {
        return false;
    }

    let navigator = window.navigator;

    const standalone = navigator.standalone;
    const userAgent = navigator.userAgent.toLowerCase();
    const safari = /safari/.test(userAgent);
    const ios = /iphone|ipod|ipad|macintosh/.test(userAgent);
    const ios_ipad_webview = ios && !safari;

    return ios
        ? (!standalone && !safari) || ios_ipad_webview
        : userAgent.includes('wv');
};

export function getWindowDimensions() {
    return { width: window.innerWidth, height: window.innerHeight };
}

export const getDeviceOrientation = () => {
    return screen.orientation
        ? screen.orientation.type
        : window.orientation === 0 || window.orientation === 180
          ? 'portrait-primary'
          : 'landscape-primary';
};

// return true if prompting for push notifications is possible, otherwise false
export const canPromptForPushNotification = () => {
    const ios = isIOS();
    const isSafari =
        navigator.userAgent.includes('Safari') &&
        !navigator.userAgent.includes('Chrome');

    if (ios || isSafari || !('Notification' in window)) return false;

    return Notification?.permission === 'default';
};
