import { gaCustomDimensions } from './config';

export const getGameDefaults = (config) => {
    const { buildVersion, buildPath, adProvider, lang, domain } = config;

    return {
        ad: { provider: adProvider },
        gameConfig: {
            buildVersion: buildVersion,
            buildUrl: `${buildPath}${buildVersion}/`,
            main: {
                Language: lang,
                AddressablesSettingsPath: `${domain}build/${buildVersion}`,
                AddressablesBundlePath: `${domain}bundle/${buildVersion}/WebGL`,
            },
            dimensions: gaCustomDimensions,
        },
    };
};
